<template>
  <div>
    <v-card
      :height="height"
      class="ma-2 d-flex align-center justify-center"
      @click="redirectToLinkedInPage"
    >
      <template>
        <span class="d-flex text-h4 font-weight-black grey--text text--darken-3"> RIYAZ </span>
      </template>
    </v-card>
  </div>
</template>
<script>
export default {
  data () {
    return {
      height: window.innerHeight - 17
    }
  },
  mounted () {
    window.onresize = () => {
      this.height = window.innerHeight - 17
    }
  },
  methods: {
    redirectToLinkedInPage () {
      window.open(process.env.VUE_APP_LINKEDIN_URL, '_self')
    }
  }
}
</script>
